import React from "react"
import Link from "gatsby-link"
import SbEditable from "storyblok-react"
import { Navbar, Nav, NavDropdown, Container, Row, Col } from "react-bootstrap"
import "./styles/navigation.scss"

const Navigation = ({ settings, lang }) => (
  <header className="w-full bg-white">
    <Navbar bg="light" expand="lg" className="main-nav">
      <Container fluid className="main-nav_container">
        <Navbar.Brand href="#home" className="logo">
          shootsta
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {settings &&
              settings.content.main_nav.map((navitem, index) => (
                <SbEditable content={navitem} key={navitem._uid}>
                  {navitem.link_type == "dropdown" && (
                    <li key={index}>
                      <NavDropdown
                        title={navitem.name}
                        id="basic-nav-dropdown"
                        className="has-megamenu"
                      >
                        <div className="megamenu">
                          <Row>
                            {navitem.NavDropDown.map(
                              (dropdownHeader, index) => (
                                <Col>
                                  <span>{dropdownHeader.name}</span>
                                  {dropdownHeader.NavDropDown.map(
                                    (dropdownitem, index) => (
                                      <div>
                                        <NavDropdown.Item href="">
                                          <img
                                            src={dropdownitem.icon.filename}
                                            className="main-nav_icon"
                                          ></img>
                                          {dropdownitem.name}
                                          <span className="main-nav_dropdown_desc">
                                            {dropdownitem.description}
                                          </span>
                                        </NavDropdown.Item>
                                      </div>
                                    )
                                  )}
                                </Col>
                              )
                            )}
                          </Row>
                        </div>
                      </NavDropdown>
                    </li>
                  )}
                  {navitem.link_type == "Link" && (
                    <li key={index}>
                      <Nav.Link
                        to={`/${navitem.link.cached_url
                          .replace("en/", "")
                          .replace("home", "")}`}
                        prefetch="true"
                        className=""
                      >
                        {navitem.name}
                      </Nav.Link>
                    </li>
                  )}
                </SbEditable>
              ))}

            <li className="justify-content-end">
              <Link
                to="/"
                className={`justify-content-end ${
                  lang === "en" ? "bg-primary text-white" : ""
                }`}
              >
                EN
              </Link>
            </li>
            <li>
              <Link
                to="/de"
                className={` ${lang === "de" ? "bg-primary text-white" : ""}`}
              >
                DE
              </Link>
            </li>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </header>
)

export default Navigation
