import React from "react"
import SbEditable from "storyblok-react"
import { Button } from "react-bootstrap"
const SWButton = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Button>{blok.text}</Button>
    </SbEditable>
  )
}

export default SWButton
