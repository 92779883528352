import React from "react"
import DynamicComponent from "./DynamicComponent"
import SbEditable from "storyblok-react"

const Grid = ({ blok }) => (
  <SbEditable content={blok} key={blok._uid}>
    <div className="custom-container">
      <div className="py-5">
        {blok.columns.map(blok => (
          <div>
            <DynamicComponent blok={blok} />
          </div>
        ))}
      </div>
    </div>
  </SbEditable>
)

export default Grid
