import React from "react"
import SbEditable from "storyblok-react"

import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-storyblok-image"
import DynamicComponent from "./DynamicComponent"

const Teaser = ({ blok }) => {
  const fluidProps = getFluidGatsbyImage(blok.hero_image.filename, {
    maxWidth: 900,
  })
  return (
    <SbEditable content={blok}>
      <div className="custom-container">
        <div className="row">
          <div className="col-6 d-flex">
            <div className="justify-content-center align-self-center">
              {" "}
              <h1 className="px-5 py-2">{blok.headline}</h1>
              <p className=" px-5 py-2">{blok.tagline}</p>
            </div>
          </div>
          <div className="col-6">
            <div className="h-100">
              <Img fluid={fluidProps} className="h-100" />
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default Teaser
