import React from "react"
import SbEditable from "storyblok-react"

import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-storyblok-image"
import DynamicComponent from "./DynamicComponent"
import { Button } from "react-bootstrap"

const Hero = ({ blok }) => {
  const fluidProps = getFluidGatsbyImage(blok.image.filename, {
    maxWidth: 900,
  })
  return (
    <SbEditable content={blok}>
      <div className="custom-container">
        <div className="row py-5">
          <div className="col-6 d-flex">
            <div className="justify-content-center align-self-center">
              {" "}
              <h1 className="px-5 py-2">{blok.headline}</h1>
              <p className=" px-5 py-2">{blok.tagline}</p>
              <div className="px-5 py-2 ">
                {" "}
                <Button variant="primary btn-large" className="btn-lg">
                  {blok.cta}
                </Button>
                <Button variant="outline-primary ml-md-2" className="btn-lg">
                  {blok.cta2}
                </Button>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="h-100">
              {" "}
              <Img fluid={fluidProps} className="h-100" />
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default Hero
